import { createContext, FC, useMemo, ReactNode, useContext } from "react";
import { useAuth } from "src/hooks/use-auth";
import {
  AxiosError,
  // AxiosRequestConfig
} from "axios";

const axios = require("axios").default;

interface EstateAppApiProviderProps {
  children: ReactNode;
}

const EstateAppApiContext = createContext({
  estateAppApiInstance: axios,
});

// interface CustomAxiosRequestConfig extends AxiosRequestConfig {
//   _retry?: boolean;
// }

export const EstateAppApiProvider: FC<EstateAppApiProviderProps> = (props) => {
  const { children } = props;
  const authStuff = useAuth();
  const estateAppApiInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SNUG_API_URL,
    });

    instance.interceptors.request.use((config: any) => {
      if (localStorage.getItem("s_at")) {
        config.headers.Authorization = "Bearer " + localStorage.getItem("s_at");
      }
      config.headers["X-User-Domain"] = window.location.host.replace(
        "https://",
        ""
      );
      return config;
    });

    instance.interceptors.response.use(
      (response: any) => response,
      async (error: AxiosError) => {
        //
        // Need to figure this out, we wnt to refresh token if it is expired, but the resulting behavior of the code below for multiple api refresh
        // was for the token to be refreshed but still be directed to login page from some refresh errors. For now we're just signing out, but ideal UX
        // would sipmly be to refresh token and continue the UX without blip.
        //
        // const originalRequest = error.config as CustomAxiosRequestConfig;
        //
        // if (error.response?.status === 401 && !originalRequest._retry) {
        //   originalRequest._retry = true;
        //   try {
        //     await authStuff.s_rt(); // Refresh the token
        //     instance.defaults.headers.common["Authorization"] =
        //       "Bearer " + localStorage.getItem("s_at");
        //     return instance(originalRequest);
        //   } catch (err) {
        //     // Handle error, show message or redirect to login page
        //   }
        // }
        if (error.response?.status === 401 || error.response?.status === 403) {
          await authStuff.signOut();
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [authStuff]);

  return (
    <EstateAppApiContext.Provider
      value={{
        estateAppApiInstance,
      }}
    >
      {children}
    </EstateAppApiContext.Provider>
  );
};

export const useEstateAppApiContext = () => useContext(EstateAppApiContext);
