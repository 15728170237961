import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
  ChangeEvent,
  MouseEvent,
} from "react";
import toast from "react-hot-toast";
import type { File } from "src/components/file-dropzone";
import {
  GenericPath,
  useUploadProfessionalGroupImage,
  useUploadProfessionalGroupRoleImage,
  useV3Create,
  useV3DeleteById,
  useV3DocumentDownload,
  useV3GenericGetData,
  useV3GenericGetDataWithPagination,
  useV3Update,
  useV3UploadMultipleFiles,
} from "src/utils/snug/estateAppApi";
import { allRoleTypesArray, downloadFileFromS3 } from "src/utils/snug/snug";
import { useMounted } from "src/hooks/use-mounted";
import {
  AuthorizationRoleTypes,
  ClientRoleListFirstDegreePeopleRole,
  DocumentV2,
  ProfessionalGroup,
  ProfessionalGroupRole,
  UserDataObj,
  WhiteLabelConfigCreate,
} from "src/types/snugtotal";
import { slackHook } from "src/utils/snug/slackHooks";
import sortBy from "lodash/sortBy";
import { User } from "@auth0/auth0-spa-js";
import { useAuth } from "src/hooks/use-auth";
import { SplashScreen } from "src/components/splash-screen";
import { paths } from "src/paths";
import { useRouter } from "src/hooks/use-router";
import { demoTransactions } from "src/utils/snug/constants";
import useWhiteLabelConfig from "./config-context";
import { gtm } from "src/libs/gtm";

type SortDir = "asc" | "desc";

export interface PatchableProGroupRoleObject {
  professional_email?: string;
  professional_phone?: string;
  professional_address?: string;
  professional_image?: string;
  professional_source_image?: string;
  default_will_price?: number;
  default_trust_price?: number;
  professional_website?: string;
  professional_bio?: string;
}

interface ClientRolesSearchState {
  filter?: string;
  page: number;
  rowsPerPage: number;
  sortBy?: string;
  sortDir?: SortDir;
}

const useClientRoleSearch = () => {
  const [searchAndPaginationState, setSearchAndPaginationState] =
    useState<ClientRolesSearchState>({
      filter: undefined,
      page: 0,
      rowsPerPage: 25,
      sortBy: "created_at",
      sortDir: "desc",
    });

  const handleFiltersChange = useCallback((filter: string): void => {
    setSearchAndPaginationState((prevState) => ({
      ...prevState,
      filter,
    }));
  }, []);

  const handleSortChange = useCallback(
    (sortBy: string, sortDir: SortDir): void => {
      setSearchAndPaginationState((prevState) => ({
        ...prevState,
        sortBy,
        sortDir,
      }));
    },
    []
  );

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number): void => {
      setSearchAndPaginationState((prevState) => ({
        ...prevState,
        page,
      }));
    },
    []
  );

  const handleRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setSearchAndPaginationState((prevState) => ({
        ...prevState,
        rowsPerPage: parseInt(event.target.value, 10),
      }));
    },
    []
  );

  return {
    handleFiltersChange,
    handleSortChange,
    handlePageChange,
    handleRowsPerPageChange,
    searchAndPaginationState,
  };
};

// Use lodash to sort the people roles based on allRoleTypesArray
const sortPeopleByRoleUsingLodash = (
  people: ClientRoleListFirstDegreePeopleRole[]
): ClientRoleListFirstDegreePeopleRole[] => {
  // Create a map for quick lookup of index positions based on role
  const roleIndexMap = new Map(
    allRoleTypesArray.map((role, index) => [role, index])
  );

  return sortBy(people, [
    (person) => roleIndexMap.get(person.role || "") ?? Infinity,
  ]);
};

type clientRolesStoreAction =
  | { type: "SET_CLIENT_ROLES"; payload: ClientRoleListFirstDegreePeopleRole[] }
  | { type: "SET_CLIENT_ROLES_SEARCH_COUNT"; payload: number }
  | { type: "SET_CLIENT_ROLES_TOTAL_COUNT"; payload: number }
  | { type: "SET_CLIENT_ROLES_TOTAL_COUNT_LOADING"; payload: boolean }
  | {
    type: "ADD_OR_UPDATE_CLIENT_ROLES";
    payload: ClientRoleListFirstDegreePeopleRole;
  }
  | { type: "DELETE_CLIENT_ROLES"; payload: string }
  | { type: "SET_CLIENT_ROLES_LOADING"; payload: boolean }
  | { type: "SET_CLIENT_ROLES_SAVING_NEW"; payload: boolean }
  | { type: "SET_CLIENT_ROLES_FILES_TO_UPLOAD"; payload: File[] }
  | { type: "SET_ASSUMED_ACCOUNT_ROLE"; payload: AuthorizationRoleTypes | null }
  | { type: "SET_TARGET_USER_DATAS"; payload: UserDataObj[] }
  | { type: "SET_TARGET_USER_DATA_SAVING"; payload: boolean }
  | { type: "ADD_OR_UPDATE_TARGET_USER_DATA"; payload: UserDataObj }
  | { type: "SET_USERDATA"; payload: UserDataObj }
  | { type: "SET_USERDATA_INITIAL_LOADING"; payload: boolean }
  | { type: "SET_USERDATA_SAVING"; payload: boolean }
  | { type: "SET_PROFESSIONAL_GROUP"; payload: ProfessionalGroup | null };

// Define reducer function for useclientRolesStore
const clientRolesStoreReducer = (
  state: UserDataContextStoreState,
  action: clientRolesStoreAction
): UserDataContextStoreState => {
  switch (action.type) {
    case "SET_CLIENT_ROLES":
      const sortedclientRoles = sortPeopleByRoleUsingLodash(action.payload);
      return { ...state, clientRoles: sortedclientRoles };
    // SET_CLIENT_ROLES_SEARCH_COUNT
    case "SET_CLIENT_ROLES_SEARCH_COUNT":
      return { ...state, clientRolesSearchCount: action.payload };
    case "SET_CLIENT_ROLES_TOTAL_COUNT":
      return { ...state, clientRolesTotalCount: action.payload };
    case "SET_CLIENT_ROLES_TOTAL_COUNT_LOADING":
      return { ...state, clientRolesTotalCountLoading: action.payload };
    case "ADD_OR_UPDATE_CLIENT_ROLES":
      const clientRoles = state.clientRoles;
      const index = clientRoles.findIndex((pr) => pr.id === action.payload.id);
      if (index > -1) {
        clientRoles[index] = action.payload;
      } else {
        clientRoles.push(action.payload);
      }
      return { ...state, clientRoles };
    case "DELETE_CLIENT_ROLES":
      return {
        ...state,
        clientRoles: state.clientRoles.filter((pr) => pr.id !== action.payload),
      };
    case "SET_CLIENT_ROLES_LOADING":
      return { ...state, clientRolesLoading: action.payload };
    case "SET_CLIENT_ROLES_SAVING_NEW":
      return { ...state, clientRolesSavingNew: action.payload };
    case "SET_CLIENT_ROLES_FILES_TO_UPLOAD":
      return { ...state, clientRolesFilestoUpload: action.payload };
    case "SET_TARGET_USER_DATA_SAVING":
      return { ...state, targetUserDataSaving: action.payload };
    case "SET_TARGET_USER_DATAS":
      return { ...state, targetUserDatas: action.payload };
    case "ADD_OR_UPDATE_TARGET_USER_DATA":
      const targetUserDatas = state.targetUserDatas;
      const targetUserDataIndex = targetUserDatas.findIndex(
        (ud) => ud.ud_id === action.payload.ud_id
      );
      if (targetUserDataIndex > -1) {
        targetUserDatas[targetUserDataIndex] = action.payload;
      } else {
        targetUserDatas.push(action.payload);
      }
      return { ...state, targetUserDatas };

    case "SET_USERDATA":
      return { ...state, userData: action.payload };
    case "SET_USERDATA_INITIAL_LOADING":
      return { ...state, userDataInitialLoading: action.payload };
    case "SET_USERDATA_SAVING":
      return { ...state, userDataSaving: action.payload };
    case "SET_PROFESSIONAL_GROUP":
      return { ...state, professionalGroup: action.payload };
    default:
      return state;
  }
};

export interface UserDataContexType {
  userData: UserDataObj | null;
  targetUserDatas: UserDataObj[];
  userDataInitialLoading: boolean;
  userDataSaving: boolean;

  clientRoles: ClientRoleListFirstDegreePeopleRole[];
  clientRolesSearchCount: number;
  clientRolesTotalCount: number;
  clientRolesTotalCountLoading: boolean;
  clientRolesLoading: boolean;
  clientRolesSavingNew: boolean;
  clientRolesFilestoUpload: File[];
  professionalGroup: ProfessionalGroup | null;
  professionalGroupSaving: boolean;
  searchAndPaginationState: ClientRolesSearchState;
  isFirmAdmin: boolean;
  isWhiteLabel: boolean;
  isCobranding: boolean;
  isProTier: boolean;
  // handleFiltersChange: (filters: Filters) => void;
  handleFiltersChange: (filter: string) => void;
  handleSortChange?: (sortBy: string, sortDir: SortDir) => void;
  handlePageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  handleRowsPerPageChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleUpdateProfessionalGroup: (data: ProfessionalGroup) => Promise<void>;
  handleUpdateProfessionalGroupImage: (
    file: File,
    sourceFile: File,
    professionalGroup: ProfessionalGroup | null
  ) => Promise<void>;
  handleDeleteProGroupImage: () => Promise<void>;
  handleArchiveClientRole: (client_role_id: string | null) => Promise<void>;
  handleDownloadClientEstateDocument: (
    ud_id: string,
    estateDocumentId: string,
    document: DocumentV2 | null
  ) => Promise<void>;
  handleclientRolesFileRemove: (file: File) => void;
  handleclientRolesFilesDrop: (newFiles: File[]) => void;
  handleclientRolesFilesRemoveAll: () => void;
  handleUploadClientRolesFiles: (entityId: string | null) => void;
  handleAddOrEditTargetUserData: (
    target_ud_id: string,
    userData: UserDataObj
  ) => Promise<UserDataObj | null>;

  handleUpdateProfessionalImage: (
    file: File,
    sourceFile: File
  ) => Promise<void>;
  handleDeleteProfessionalImage: () => Promise<void>;
  handleAddOrEditOwnerUserData: (
    userData: UserDataObj,
    proGroupRoleUpdate?: PatchableProGroupRoleObject
  ) => Promise<UserDataObj | null>;
  handleInvitePro: (
    professional_group_id: string,
    name: string,
    email: string,
    role: "PRO_GROUP_ADMIN" | "PRO_GROUP_AGENT",
    allow_custom_client_pricing: boolean
  ) => void;
  handleAddClient: (data: any) => Promise<ClientRoleListFirstDegreePeopleRole>;
  handleInviteClient: (
    client_role_id: string | null,
    customMessage: string | null,
    clientNotification?: boolean
  ) => void;
  handleSecondDegreeInvite: (targetUserData: UserDataObj | null) => void;
  handleUpgradeToAdmin: (role_id: string) => void;
  handleDowngradeToAgent: (role_id: string) => void;
  handleDeleteProGroupRole: (
    role_id: string,
    new_user_data_ud_id: string
  ) => void;
  handleAdminUpdateClient: (
    client_role_id: string,
    professional_pricing_option: string | null,
    will_price: number | null,
    trust_price: number | null,
    block_trust: boolean | null
  ) => Promise<ClientRoleListFirstDegreePeopleRole>;
  handleTransferClient: (
    client_role_id: string,
    transfer_to_professional_role_id: string,
    transfer_from_professional_role_id: string
  ) => void;
  handleRequestAccessFromLead: (client_role_id: string) => void;
  handleEditClientEmail: (
    client_role_id: string,
    email: string,
    send_invite: boolean
  ) => Promise<void>;
  handleCreateWhiteLabelConfigOnProGroup: (
    data: WhiteLabelConfigCreate
  ) => void;
  handleRefreshProGroup: () => Promise<ProfessionalGroup | null>;
  handlePatchSelfProfessionalGroupRole: (
    data: PatchableProGroupRoleObject
  ) => Promise<ProfessionalGroupRole | void>;
}

interface UserDataContextStoreState {
  clientRoles: ClientRoleListFirstDegreePeopleRole[];
  clientRolesSearchCount: number;
  clientRolesTotalCount: number;
  clientRolesTotalCountLoading: boolean;
  clientRolesLoading: boolean;
  clientRolesSavingNew: boolean;
  clientRolesFilestoUpload: File[];
  targetAuthRoles: ClientRoleListFirstDegreePeopleRole[];
  targetAuthRolesLoading: boolean;
  targetAuthRolesSavingNew: boolean;
  targetUserDataSaving: boolean;
  targetUserDatas: UserDataObj[];
  professionalGroup: ProfessionalGroup | null;
  userData: UserDataObj | null;
  userDataInitialLoading: boolean;
  userDataSaving: boolean;
}

export const UserDataContext = createContext<UserDataContexType>(
  {} as UserDataContexType
);

interface UserDataProviderProps {
  children: ReactNode;
}

// Possible refactore would be to handle api selection here
export const UserDataProvider: FC<UserDataProviderProps> = (props) => {
  const { children } = props;
  const isMounted = useMounted();
  const { user, signOut } = useAuth();
  const router = useRouter();
  const { email_sender, dashboard_domain } = useWhiteLabelConfig();
  const {
    handleFiltersChange,
    handleSortChange,
    handlePageChange,
    handleRowsPerPageChange,
    searchAndPaginationState,
  } = useClientRoleSearch();

  const [state, dispatch] = useReducer(clientRolesStoreReducer, {
    clientRoles: [],
    clientRolesSearchCount: 0,
    clientRolesTotalCount: 0,
    clientRolesTotalCountLoading: false,
    clientRolesLoading: true,
    clientRolesSavingNew: false,
    clientRolesFilestoUpload: [],
    targetAuthRoles: [],
    targetAuthRolesLoading: true,
    targetAuthRolesSavingNew: false,
    targetUserDataSaving: false,
    targetUserDatas: [],
    professionalGroup: null,
    userData: null,
    userDataInitialLoading: true,
    userDataSaving: false,
  });

  const deleteRoleClientById = useV3DeleteById(
    GenericPath.GENERIC_SUBCATEGORY_ITEM_RETRIEVE_UPDATE_DELETE_V3
  );

  const downloadDocument = useV3DocumentDownload(
    GenericPath.GENERIC_DOCUMENT_DOWNLOAD_V3
  );

  const addClient = useV3Create<ClientRoleListFirstDegreePeopleRole>(
    GenericPath.GENERIC_SUBCATEGORY_ITEM_LIST_CREATE_V3
  );
  const analyzeDocument =
    useV3UploadMultipleFiles<ClientRoleListFirstDegreePeopleRole>(
      GenericPath.GENERIC_SUBCATEGORY_ITEM_RETRIEVE_UPDATE_DELETE_V3
    );

  const adminPathClientRole = useV3Update<ClientRoleListFirstDegreePeopleRole>(
    GenericPath.GENERIC_SUBCATEGORY_ITEM_RETRIEVE_UPDATE_DELETE_V3
  );

  const genericGet =
    useV3GenericGetDataWithPagination<ClientRoleListFirstDegreePeopleRole>(
      GenericPath.GENERIC_SUBCATEGORY_ITEM_LIST_CREATE_V3
    );

  const sendSecondDegreeInvite = useV3Create<UserDataObj>(
    GenericPath.SECOND_DEGREE_INVITE
  );

  const genericGetUserData = useV3GenericGetData<UserDataObj>(
    GenericPath.USER_DATA_V3
  );
  const createUserData = useV3Create<UserDataObj>(GenericPath.USER_DATA_V3);
  const updateUserData = useV3Update<UserDataObj>(GenericPath.USER_DATA_V3);
  const removeProfessionalImage = useV3DeleteById(GenericPath.PRO_IMAGE);
  const updateProfessionalImage =
    useUploadProfessionalGroupRoleImage<ProfessionalGroupRole>(
      GenericPath.PRO_IMAGE
    );
  const invitePro = useV3Create<ProfessionalGroup>(
    GenericPath.PRO_GROUP_INVITE
  );
  const sendClientInvite = useV3Create(GenericPath.CLIENT_INVITE);
  const transferClient = useV3Update<ClientRoleListFirstDegreePeopleRole>(
    GenericPath.CLIENT_TRANSFER
  );
  const upgradeToAdmin = useV3Create<ProfessionalGroup>(
    GenericPath.PRO_GROUP_ROLE_UPGRADE
  );
  const downgradeToAgent = useV3Create<ProfessionalGroup>(
    GenericPath.PRO_GROUP_ROLE_DOWNGRADE
  );
  const deleteProGroupRole = useV3DeleteById(
    GenericPath.GENERIC_SUBCATEGORY_ITEM_RETRIEVE_UPDATE_DELETE_V3
  );
  const requestAccessFromLead =
    useV3Update<ClientRoleListFirstDegreePeopleRole>(
      GenericPath.LEAD_REQUEST_ACCESS
    );
  const editClientEmail = useV3Update<ClientRoleListFirstDegreePeopleRole>(
    GenericPath.CLIENT_EDIT_EMAIL
  );
  const getClientCount = useV3GenericGetData<{ count: number }>(
    GenericPath.CLIENT_COUNT
  );
  const patchSelfProfessionalGroupRole = useV3Update<ProfessionalGroupRole>(
    GenericPath.GENERIC_SUBCATEGORY_ITEM_RETRIEVE_UPDATE_DELETE_V3
  );

  const handlePatchSelfProfessionalGroupRole = useCallback(
    async (
      data: PatchableProGroupRoleObject
    ): Promise<ProfessionalGroupRole | void> => {
      try {
        if (!state.userData?.ud_id) {
          console.error("No user data id provided");
          return;
        }

        const params = {
          ud_id: state.userData.ud_id,
          entity: "pro-group",
          entity_id: state.professionalGroup?.id || "",
          subcategory: "professional-group-role",
          subcategory_id:
            state.userData.professional_group_role_user_data?.id || "",
        };

        // strip fields from data that are null, undefined, or empty string
        Object.keys(data).forEach(
          (key) =>
            (data[key as keyof PatchableProGroupRoleObject] == null ||
              data[key as keyof PatchableProGroupRoleObject] === "") &&
            delete data[key as keyof PatchableProGroupRoleObject]
        );
        if (Object.keys(data).length === 0) {
          return;
        }

        return await patchSelfProfessionalGroupRole(params, data, true);
      } catch (err) {
        console.error(err);
      }
    },
    [
      patchSelfProfessionalGroupRole,
      state.professionalGroup?.id,
      state.userData?.professional_group_role_user_data?.id,
      state.userData?.ud_id,
    ]
  );

  const handleAddOrEditOwnerUserData = useCallback(
    async (
      userData: UserDataObj,
      proGroupRoleUpdate?: PatchableProGroupRoleObject
    ): Promise<UserDataObj | null> => {
      try {
        dispatch({ type: "SET_USERDATA_SAVING", payload: true });
        const params = { ud_id: "", entity: "user_data" };
        if (userData.ud_id) {
          let proGroupRole = { ...userData.professional_group_role_user_data };
          if (proGroupRoleUpdate) {
            const proGroupRoleRes = await handlePatchSelfProfessionalGroupRole(
              proGroupRoleUpdate
            );
            if (proGroupRoleRes) {
              proGroupRole = proGroupRoleRes;
            }
          }

          const res = await updateUserData(params, userData, true);
          dispatch({
            type: "SET_USERDATA",
            payload: {
              ...res,
              professional_group_role_user_data: proGroupRole,
            },
          });
          return res;
        } else {
          const res = await createUserData(params, userData);
          dispatch({ type: "SET_USERDATA", payload: res });
          return res;
        }
      } catch (err) {
        console.error(err);
        throw err;
      } finally {
        dispatch({ type: "SET_USERDATA_SAVING", payload: false });
      }
    },
    [createUserData, updateUserData, handlePatchSelfProfessionalGroupRole]
  );

  const handleRequestAccessFromLead = useCallback(
    async (client_role_id: string) => {
      if (!state.userData?.ud_id) {
        console.error("No user data id provided");
        return Promise.reject("No user data id provided");
      }

      try {
        dispatch({ type: "SET_CLIENT_ROLES_SAVING_NEW", payload: true });
        const params = {
          ud_id: state.userData?.ud_id,
          entity: "pro-group",
          entity_id: state.professionalGroup?.id || "",
          subcategory: "pro-people-roles",
          subcategory_id: client_role_id,
        };
        const res = await requestAccessFromLead(params, {}, true);
        dispatch({ type: "ADD_OR_UPDATE_CLIENT_ROLES", payload: res });
        return res;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    [requestAccessFromLead, state.userData?.ud_id, state.professionalGroup?.id]
  );

  const handleDeleteProfessionalImage = useCallback(async () => {
    if (!state.userData) {
      console.error("No user data provided");
      return Promise.reject("No user data provided");
    }

    try {
      dispatch({ type: "SET_USERDATA_SAVING", payload: true });
      const params = {
        entity_id: state.professionalGroup?.id,
        ud_id: state.userData?.ud_id,
        subcategory_id:
          state.userData.professional_group_role_user_data?.id || "",
      };
      await removeProfessionalImage(params);
      // response is empty object. remove professional image from field in state object
      const newUserData = {
        ...(state.userData as UserDataObj),
        professional_group_role_user_data: {
          ...state.userData.professional_group_role_user_data,
          professional_image: null,
        },
      };
      dispatch({ type: "SET_USERDATA", payload: newUserData });
      toast.success("Your image has been deleted!");
    } catch (error) {
      console.error(error);
      toast.error(
        "There's an error deleting your advisor image! Please try again."
      );
    } finally {
      dispatch({ type: "SET_USERDATA_SAVING", payload: false });
    }
  }, [removeProfessionalImage, state.userData, state.professionalGroup?.id]);

  const handleUpdateProfessionalImage = useCallback(
    async (file: File, sourceFile: File) => {
      try {
        dispatch({ type: "SET_USERDATA_SAVING", payload: true });
        const res = await updateProfessionalImage(
          state?.userData?.ud_id || "",
          state?.professionalGroup?.id || "",
          state?.userData?.professional_group_role_user_data?.id || "",
          file,
          sourceFile
        );
        dispatch({
          type: "SET_USERDATA",
          payload: {
            ...(state?.userData as UserDataObj),
            professional_group_role_user_data: res,
          },
        });
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        dispatch({ type: "SET_USERDATA_SAVING", payload: false });
      }
    },
    [updateProfessionalImage, state?.professionalGroup?.id, state?.userData]
  );

  const handleAddOrEditTargetUserData = useCallback(
    async (
      target_ud_id: string,
      userData: UserDataObj
    ): Promise<UserDataObj | null> => {
      if (!target_ud_id) {
        console.error("No user data id provided");
        return Promise.reject("No user data id provided");
      }

      try {
        dispatch({ type: "SET_TARGET_USER_DATA_SAVING", payload: true });
        const params = { ud_id: target_ud_id, entity: "role-target-user-data" };
        if (userData.ud_id) {
          const res = await updateUserData(
            { ...params, entity_id: userData.ud_id },
            userData
          );
          dispatch({ type: "ADD_OR_UPDATE_TARGET_USER_DATA", payload: res });
          return res;
        } else {
          const res = await createUserData(params, userData);
          dispatch({ type: "ADD_OR_UPDATE_TARGET_USER_DATA", payload: res });
          return res;
        }
      } catch (err) {
        console.error(err);
        throw err;
      } finally {
        dispatch({ type: "SET_TARGET_USER_DATA_SAVING", payload: false });
      }
    },
    [createUserData, updateUserData]
  );

  const handleclientRolesFilesDrop = useCallback(
    (newFiles: File[]): void => {
      dispatch({
        type: "SET_CLIENT_ROLES_FILES_TO_UPLOAD",
        payload: [...state.clientRolesFilestoUpload, ...newFiles],
      });
    },
    [state.clientRolesFilestoUpload]
  );

  const handleclientRolesFileRemove = useCallback(
    (file: File): void => {
      const updatedFiles = state.clientRolesFilestoUpload.filter(
        (_file) => _file.path !== file.path
      );
      dispatch({
        type: "SET_CLIENT_ROLES_FILES_TO_UPLOAD",
        payload: updatedFiles,
      });
    },
    [state.clientRolesFilestoUpload]
  );

  const handleclientRolesFilesRemoveAll = useCallback((): void => {
    dispatch({ type: "SET_CLIENT_ROLES_FILES_TO_UPLOAD", payload: [] });
  }, []);

  const handleUploadClientRolesFiles = useCallback(
    async (peopleRoleId: string | null) => {
      if (!state.userData?.ud_id || !peopleRoleId) {
        console.error(`Missing required parameters ud_id or peopleRoleId`);
        return Promise.reject("Missing required parameters");
      }
      dispatch({ type: "SET_CLIENT_ROLES_SAVING_NEW", payload: true });

      const files = state.clientRolesFilestoUpload;

      if (files.length === 0) {
        toast.error("No files to upload");
        return;
      }

      try {
        // Call the API to upload all files in one request
        const res = await analyzeDocument(
          {
            ud_id: state.userData.ud_id,
            entity: "pro-group",
            entity_id: state.professionalGroup?.id || "",
            subcategory: "client-analyze",
            subcategory_id: peopleRoleId,
          },
          files
        );

        if (res) {
          // Update state with the response
          dispatch({
            type: "ADD_OR_UPDATE_CLIENT_ROLES",
            payload: res,
          });

          // Clear the files after successful upload
          dispatch({
            type: "SET_CLIENT_ROLES_FILES_TO_UPLOAD",
            payload: [],
          });

          const successMessage = `${files.length} files uploaded successfully`;
          toast.success(successMessage);
        } else {
          const errorMessage = `${files.length} files failed to upload`;
          toast.error(errorMessage);
          slackHook(
            `Document Error: Failed to upload documents for entity_id: ${peopleRoleId}`
          );
        }
      } catch (error) {
        const errorMessage = `Failed to upload all documents`;
        toast.error(errorMessage);
        console.error(errorMessage);
        slackHook(
          `Document Error: Failed to upload documents for entity_id: ${peopleRoleId} with message "${error}"`
        );
      } finally {
        dispatch({ type: "SET_CLIENT_ROLES_SAVING_NEW", payload: false });
        // Ensure files are cleared after the upload process
        handleclientRolesFilesRemoveAll();
      }
    },
    [
      analyzeDocument,
      state.clientRolesFilestoUpload,
      state.userData?.ud_id,
      state.professionalGroup?.id,
      handleclientRolesFilesRemoveAll,
    ]
  );

  // this is the funcrtion that should die
  const handleSecondDegreeInvite = useCallback(
    async (targetUserData: UserDataObj | null) => {
      if (!targetUserData || !state.userData?.ud_id || !targetUserData.ud_id) {
        return;
      }

      try {
        dispatch({ type: "SET_TARGET_USER_DATA_SAVING", payload: true });

        await handleAddOrEditTargetUserData(
          targetUserData.ud_id,
          targetUserData
        );

        const params = {
          ud_id: state.userData?.ud_id || "",
          entity: "user-data",
          entity_id: targetUserData.ud_id || "",
        };
        await sendSecondDegreeInvite(params, {});
      } catch (err) {
        console.error(err);
        toast.error("Failed to send invite");
        throw err;
      } finally {
        dispatch({ type: "SET_TARGET_USER_DATA_SAVING", payload: false });
      }
    },
    [
      sendSecondDegreeInvite,
      handleAddOrEditTargetUserData,
      state.userData?.ud_id,
    ]
  );

  const handleDownloadClientEstateDocument = useCallback(
    async (
      ud_id: string,
      estateDocumentId: string,
      document: DocumentV2 | null
    ) => {
      if (
        !document?.document_id ||
        !estateDocumentId ||
        !state.userData?.ud_id
      ) {
        return Promise.reject("Missing required parameters");
      }
      toast.success("Downloading file...");

      const params: any = {
        document_id: document.document_id,
        entity: "estate-documents",
        entity_id: estateDocumentId,
        ud_id: ud_id,
      };

      downloadDocument(params)
        .then((res: any) => {
          if (res) {
            // download File from s3 res and get file name from mathcing document id in tax objects' documents array
            downloadFileFromS3(
              res,
              document.document_name || "Snug_Download.error"
            );
          } else {
            slackHook(
              `Document Error: Failed get document url from location with message "No response"`
            );
          }
        })
        .catch((err) => {
          toast.error("Failed to download file");
          slackHook(
            `Document Error: Failed get document url from location with message "${err}"`
          );
        });
    },
    [downloadDocument, state.userData?.ud_id]
  );

  const handleArchiveClientRole = useCallback(
    async (
      client_role_id: string | null
      // auth_role_id: string | null,
      // second_degree_auth_role_ids: (string | null)[] | null
    ): Promise<void> => {
      if (!client_role_id || !state.userData?.ud_id) {
        return Promise.reject("Missing required parameters");
      }
      try {
        dispatch({ type: "SET_CLIENT_ROLES_SAVING_NEW", payload: true });
        const params = {
          ud_id: state.userData.ud_id,
          subcategory: "pro-people-roles",
          entity: "pro-group",
          subcategory_id: client_role_id,
          entity_id:
            state.userData.professional_group_role_user_data
              ?.professional_group_id,
        };
        await deleteRoleClientById(params);
        if (isMounted()) {
          const updatedclientRoles = state.clientRoles.filter(
            (p) => p.id !== client_role_id
          );
          dispatch({ type: "SET_CLIENT_ROLES", payload: updatedclientRoles });
        }
        toast.success("Client archived successfully.", {
          duration: 5000,
        });
      } catch (err) {
        toast.error("Failed to request access. Please try again.", {
          duration: 5000,
        });
        console.error(err);
      } finally {
        if (isMounted()) {
          dispatch({ type: "SET_CLIENT_ROLES_SAVING_NEW", payload: false });
        }
      }
    },
    [
      isMounted,
      state.clientRoles,
      deleteRoleClientById,
      state.userData?.ud_id,
      state.userData?.professional_group_role_user_data?.professional_group_id,
    ]
  );

  const handleGetClientRoles = useCallback(
    async (
      userData: UserDataObj,
      searchAndPaginationState: ClientRolesSearchState,
      signal?: AbortSignal
    ) => {
      if (!userData.ud_id || !userData.professional_group_role_user_data?.professional_group_id || !userData.full_name) {
        return;
      }

      const params = {
        ud_id: userData.ud_id,
        entity: "pro-group",
        entity_id: userData.professional_group_role_user_data?.professional_group_id,
        subcategory: "pro-people-roles",
      };
      const queryParams = {
        page: searchAndPaginationState.page + 1, // assuming 1-based pagination on the backend
        page_size: searchAndPaginationState.rowsPerPage,
        search: searchAndPaginationState.filter,
        ordering: `${searchAndPaginationState.sortDir === "desc" ? "-" : ""}${searchAndPaginationState.sortBy
          }`,
        role: "FINANCIAL_PROFESSIONAL_CLIENT",
      };
      try {
        dispatch({ type: "SET_CLIENT_ROLES_LOADING", payload: true });
        const response = await genericGet(params, queryParams, signal);
        if (isMounted()) {
          const roles = response.data;

          dispatch({
            type: "SET_CLIENT_ROLES",
            payload: [
              {
                role: "FINANCIAL_PROFESSIONAL_CLIENT",
                is_demo_client: true,
                professional_pricing_option: "ON_PRO_TAB",
                role_target_user_data: {
                  ...userData,
                  full_name: userData.full_name,
                },
                id: null,
                archived: false,
                professional_invite_sent: true,
                user_data: {
                  ud_id: userData.ud_id,
                },
              } as ClientRoleListFirstDegreePeopleRole,
              ...roles,
            ],
          });
          dispatch({
            type: "SET_CLIENT_ROLES_SEARCH_COUNT",
            payload: response.count,
          });
        }
      } catch (err) {
        console.error('test', err);
      } finally {
        dispatch({ type: "SET_CLIENT_ROLES_LOADING", payload: false });
      }
    },
    [
      isMounted,
      genericGet,
    ]
  );

  const handleGetClientCount = useCallback(
    async (userData: UserDataObj, signal?: AbortSignal) => {
      if (!userData.ud_id || !userData.professional_group_role_user_data?.professional_group_id) {
        return;
      }

      dispatch({ type: "SET_CLIENT_ROLES_TOTAL_COUNT_LOADING", payload: true });

      const params = {
        ud_id: userData.ud_id,
        entity: "pro-group",
        entity_id: userData.professional_group_role_user_data?.professional_group_id,
        subcategory: "pro-people-roles",
      };
      try {
        const response = await getClientCount(params, signal);
        if (isMounted()) {
          dispatch({
            type: "SET_CLIENT_ROLES_TOTAL_COUNT",
            payload: response.count,
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        if (isMounted()) {
          dispatch({
            type: "SET_CLIENT_ROLES_TOTAL_COUNT_LOADING",
            payload: false,
          });
        }
      }
    },
    [getClientCount, isMounted]
  );

  const handleAdminUpdateClient = useCallback(
    async (
      client_role_id: string,
      professional_pricing_option: string | null,
      will_price: number | null,
      trust_price: number | null,
      block_trust: boolean | null
    ): Promise<ClientRoleListFirstDegreePeopleRole> => {
      if (!client_role_id || !state.userData?.ud_id) {
        console.error("No client role id provided");
        return Promise.reject("No client role id provided");
      }

      try {
        dispatch({ type: "SET_CLIENT_ROLES_SAVING_NEW", payload: true });
        const params = {
          ud_id: state.userData.ud_id,
          entity: "pro-group",
          entity_id: state.professionalGroup?.id || "",
          subcategory: "pro-people-roles",
          subcategory_id: client_role_id,
        };

        const data: any = {};

        // add fields to data if they are not null
        if (professional_pricing_option !== null) {
          data.professional_pricing_option = professional_pricing_option;
        }
        if (will_price !== null) {
          data.will_price = will_price;
        }
        if (trust_price !== null) {
          data.trust_price = trust_price;
        }
        if (block_trust !== null) {
          data.block_trust = block_trust;
        }

        const res = await adminPathClientRole(params, data, true);
        dispatch({ type: "ADD_OR_UPDATE_CLIENT_ROLES", payload: res });
        return res;
      } catch (err) {
        console.error(err);
        throw err;
      } finally {
        dispatch({ type: "SET_CLIENT_ROLES_SAVING_NEW", payload: false });
      }
    },
    [adminPathClientRole, state.userData?.ud_id, state.professionalGroup?.id]
  );

  const handleAddClient = useCallback(
    async (data: any): Promise<ClientRoleListFirstDegreePeopleRole> => {
      if (!state.userData?.ud_id || !data.client_data.contact_email) {
        console.error("No user data id provided");
        return Promise.reject("No user data id provided");
      }

      if (!data.spouse_data.contact_email) {
        // pop the spouse data if there's no email
        delete data.spouse_data;
      }

      try {
        dispatch({ type: "SET_CLIENT_ROLES_SAVING_NEW", payload: true });
        const params = {
          ud_id: state.userData.ud_id,
          entity_id: state.professionalGroup?.id || "",
          entity: "pro-group",
          subcategory: "pro-people-roles",
        };
        const res = await addClient(params, data);
        dispatch({ type: "ADD_OR_UPDATE_CLIENT_ROLES", payload: res });
        return res;
      } catch (err) {
        // if error code is 400, it likely means the client already exists, so we should show a toast message with the error message from the response
        if (err.response?.status === 400) {
          toast.error(
            err.response?.data?.detail ||
            "Error adding client - check to see if client already exists"
          );
        } else {
          toast.error(
            `Failed to add client! Please try again or reach out to us at ${email_sender}`
          );
        }
        console.error(err);
        throw err;
      } finally {
        dispatch({ type: "SET_CLIENT_ROLES_SAVING_NEW", payload: false });
      }
    },
    [
      addClient,
      state.userData?.ud_id,
      state.professionalGroup?.id,
      email_sender,
    ]
  );

  ////////////////////////////// PROFESSIONAL GROUP //////////////////////////////
  ////////////////////////////// PROFESSIONAL GROUP //////////////////////////////

  const [professionalGroupSaving, setProfessionalGroupSaving] = useState(false);
  const [professionalGroupLoading, setProfessionalGroupLoading] =
    useState(true);
  const getProfessionalGroup = useV3GenericGetData<ProfessionalGroup>(
    GenericPath.GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3
  );
  const updateProfessionalGroup = useV3Update<ProfessionalGroup>(
    GenericPath.GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3
  );
  const createWhiteLabelConfigOnProGroup = useV3Create<ProfessionalGroup>(
    GenericPath.WHITE_LABEL_CONFIG_CREATE
  );
  const updateProfessionalGroupImage =
    useUploadProfessionalGroupImage<ProfessionalGroup>(
      GenericPath.PRO_GROUP_IMAGE
    );

  const removeProfessionalGroupImage = useV3DeleteById(
    GenericPath.PRO_GROUP_IMAGE
  );

  const handleUpdateProfessionalGroup = useCallback(
    async (data: ProfessionalGroup) => {
      if (!state.userData?.ud_id) return;

      try {
        setProfessionalGroupSaving(true);
        const params = {
          ud_id: state.userData.ud_id,
          entity: "pro-group",
          entity_id: data?.id || "",
        };
        const res = await updateProfessionalGroup(params, data);
        dispatch({ type: "SET_PROFESSIONAL_GROUP", payload: res });
      } catch (error) {
        console.error(error);
        toast.error(
          "There's an error updating your professional professionalGroup profile!"
        );
      } finally {
        setProfessionalGroupSaving(false);
      }
    },
    [updateProfessionalGroup, state.userData?.ud_id]
  );

  const handleUpdateProfessionalGroupImage = useCallback(
    async (
      file: File,
      sourceFile: File,
      professionalGroup: ProfessionalGroup | null
    ) => {
      try {
        setProfessionalGroupSaving(true);
        const res = await updateProfessionalGroupImage(
          state.userData?.ud_id || "",
          professionalGroup?.id || "",
          file,
          sourceFile
        );
        dispatch({ type: "SET_PROFESSIONAL_GROUP", payload: res });
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        setProfessionalGroupSaving(false);
      }
    },
    [updateProfessionalGroupImage, state.userData?.ud_id]
  );

  const handleDeleteProGroupImage = useCallback(async () => {
    try {
      setProfessionalGroupSaving(true);
      const res = await removeProfessionalGroupImage("_id");
      dispatch({ type: "SET_PROFESSIONAL_GROUP", payload: res });
      toast.success("Your image has been deleted!");
    } catch (error) {
      console.error(error);
      toast.error(
        "There's an error deleting your professional professionalGroup image! Please try again."
      );
    } finally {
      setProfessionalGroupSaving(false);
    }
  }, [removeProfessionalGroupImage]);

  const handleGetProfessionalGroup = useCallback(
    async (proGroupId?: string, signal?: AbortSignal) => {
      try {
        const params = {
          entity: "pro-group",
          entity_id: proGroupId,
          ud_id: state.userData?.ud_id || "",
        };
        const res = await getProfessionalGroup(params, signal);
        dispatch({ type: "SET_PROFESSIONAL_GROUP", payload: res });
      } catch (error) {
        console.error(error);
      } finally {
        setProfessionalGroupLoading(false);
      }
    },
    [getProfessionalGroup, state.userData?.ud_id]
  );

  const handleInvitePro = useCallback(
    async (
      professional_group_id: string,
      name: string,
      email: string,
      role: "PRO_GROUP_ADMIN" | "PRO_GROUP_AGENT",
      allow_custom_client_pricing: boolean
    ) => {
      if (!state.userData?.ud_id) {
        return;
      }

      try {
        const params = {
          ud_id: state.userData?.ud_id,
          entity: "pro-group",
          entity_id: professional_group_id,
        };

        const res = await invitePro(params, {
          name,
          email,
          role,
          allow_custom_client_pricing,
        });
        // res is updated professional group
        dispatch({ type: "SET_PROFESSIONAL_GROUP", payload: res });
      } catch (error) {
        console.error(error);
        toast.error("Failed to invite professional!");
      }
    },
    [state.userData?.ud_id, invitePro]
  );

  const handleInviteClient = useCallback(
    async (
      client_role_id: string | null,
      customMessage: string | null,
      clientNotification: boolean = false
    ) => {
      if (
        !state.userData?.ud_id ||
        !state.professionalGroup?.id ||
        !client_role_id
      ) {
        return;
      }

      try {
        const params = {
          ud_id: state.userData?.ud_id,
          entity: "pro-group",
          entity_id: state.professionalGroup?.id,
          subcategory: "pro-people-roles",
          subcategory_id: client_role_id,
        };

        await sendClientInvite(params, {
          custom_message: customMessage,
        });

        if (clientNotification) {
          toast.success("Client invitation sent!");
        }
      } catch (error) {
        console.error(error);
        toast.error("Failed to invite client!");
      }
    },
    [state.userData?.ud_id, sendClientInvite, state.professionalGroup?.id]
  );

  const handleTransferClient = useCallback(
    async (
      client_role_id: string,
      transfer_to_professional_role_id: string,
      transfer_from_professional_role_id: string
    ) => {
      if (
        !state.userData?.ud_id ||
        !state.professionalGroup?.id ||
        !client_role_id
      ) {
        return;
      }

      try {
        const params = {
          ud_id: state.userData?.ud_id,
          entity: "pro-group",
          entity_id: state.professionalGroup?.id,
          subcategory: "pro-people-roles",
          subcategory_id: client_role_id,
        };

        const res = await transferClient(
          params,
          {
            transfer_to_professional_role_id: transfer_to_professional_role_id,
            transfer_from_professional_role_id:
              transfer_from_professional_role_id,
          },
          true
        );

        // response is updated client role. update in state
        dispatch({
          type: "ADD_OR_UPDATE_CLIENT_ROLES",
          payload: res,
        });
        toast.success("Client transferred!");
      } catch (error) {
        console.error(error);
        toast.error("Failed to transfer client!");
      }
    },
    [state.userData?.ud_id, transferClient, state.professionalGroup?.id]
  );

  const handleUpgradeToAdmin = useCallback(
    async (pro_role_id: string) => {
      if (
        !state.userData?.ud_id ||
        !state.professionalGroup?.id ||
        !pro_role_id
      ) {
        return;
      }

      try {
        const params = {
          ud_id: state.userData?.ud_id,
          entity: "pro-group",
          entity_id: state.professionalGroup?.id,
          subcategory: "role",
          subcategory_id: pro_role_id,
        };

        const res = await upgradeToAdmin(params, {});

        toast.success("Team member role set to admin!");
        // response is updated professional group. set it to state
        dispatch({ type: "SET_PROFESSIONAL_GROUP", payload: res });
      } catch (error) {
        console.error(error);
        toast.error("Failed to update team member role");
      }
    },
    [state.userData?.ud_id, upgradeToAdmin, state.professionalGroup?.id]
  );

  const handleDowngradeToAgent = useCallback(
    async (pro_role_id: string) => {
      if (
        !state.userData?.ud_id ||
        !state.professionalGroup?.id ||
        !pro_role_id
      ) {
        return;
      }

      try {
        const params = {
          ud_id: state.userData?.ud_id,
          entity: "pro-group",
          entity_id: state.professionalGroup?.id,
          subcategory: "role",
          subcategory_id: pro_role_id,
        };

        const res = await downgradeToAgent(params, {});

        toast.success("Team member role set to agent!");
        // response is updated professional group. set it to state
        dispatch({ type: "SET_PROFESSIONAL_GROUP", payload: res });
      } catch (error) {
        console.error(error);
        toast.error("Failed to update team member role");
      }
    },
    [state.userData?.ud_id, downgradeToAgent, state.professionalGroup?.id]
  );

  const handleDeleteProGroupRole = useCallback(
    async (pro_role_id: string, pro_group_role_id: string) => {
      if (
        !state.userData?.ud_id ||
        !state.professionalGroup?.id ||
        !pro_role_id
      ) {
        return;
      }

      try {
        const params = {
          ud_id: state.userData?.ud_id,
          entity: "pro-group",
          entity_id: state.professionalGroup?.id,
          subcategory: "role",
          subcategory_id: pro_role_id,
        };
        const queryParams = {
          pro_group_role_id,
        };
        await deleteProGroupRole(params, queryParams);

        // response is empty, remove the role from the list in the pro group state object: professional_group_role_pro_group by id
        const updatedRoles =
          state.professionalGroup?.professional_group_role_pro_group?.filter(
            (role) => role.id !== pro_role_id
          );
        if (state.professionalGroup) {
          const updatedProfessionalGroup = {
            ...state.professionalGroup,
            professional_group_role_pro_group: updatedRoles || [],
          };
          dispatch({
            type: "SET_PROFESSIONAL_GROUP",
            payload: updatedProfessionalGroup,
          });
        }
      } catch (error) {
        console.error(error);
        toast.error("Failed to delete client role!");
      }
    },
    [state.userData?.ud_id, deleteProGroupRole, state.professionalGroup]
  );

  const handleEditClientEmail = useCallback(
    async (client_role_id: string, email: string, send_invite: boolean) => {
      if (!state.userData?.ud_id || !client_role_id) {
        return;
      }

      try {
        const params = {
          ud_id: state.userData?.ud_id,
          entity: "pro-group",
          entity_id: state.professionalGroup?.id || "",
          subcategory: "pro-people-roles",
          subcategory_id: client_role_id,
        };

        const res = await editClientEmail(params, { email, send_invite }, true);

        // response is updated client role. update in state
        dispatch({
          type: "ADD_OR_UPDATE_CLIENT_ROLES",
          payload: res,
        });
        toast.success("Client email updated!");
      } catch (error) {
        console.error(error);
        toast.error("Failed to update client email!");
      }
    },
    [state.userData?.ud_id, editClientEmail, state.professionalGroup?.id]
  );

  const handleCreateWhiteLabelConfigOnProGroup = useCallback(
    async (data: WhiteLabelConfigCreate) => {
      if (!state.userData?.ud_id || !state.professionalGroup?.id) {
        return;
      }

      try {
        setProfessionalGroupSaving(true);
        const params = {
          ud_id: state.userData.ud_id,
          entity: "pro-group",
          entity_id: state.professionalGroup?.id,
        };
        const res = await createWhiteLabelConfigOnProGroup(params, data);
        dispatch({ type: "SET_PROFESSIONAL_GROUP", payload: res });
      } catch (error) {
        console.error(error);
        slackHook(
          `White Label Error: Failed to create white label config for entity_id: ${state.professionalGroup?.id} with message "${error}"`
        );
        toast.error(
          "There's an error updating your white label config profile! There is a conflict in your configuration. We have been notified and will reach out to you shortly."
        );
      } finally {
        setProfessionalGroupSaving(false);
      }
    },
    [
      createWhiteLabelConfigOnProGroup,
      state.userData?.ud_id,
      state.professionalGroup?.id,
    ]
  );

  const handleRefreshProGroup =
    useCallback(async (): Promise<ProfessionalGroup | null> => {
      if (
        !state.userData?.ud_id ||
        !state.userData?.professional_group_role_user_data
          ?.professional_group_id
      ) {
        return null;
      }
      setProfessionalGroupSaving(true);
      try {
        const params = {
          entity: "pro-group",
          entity_id:
            state.userData?.professional_group_role_user_data
              .professional_group_id,
          ud_id: state.userData?.ud_id || "",
        };
        const res = await getProfessionalGroup(params);
        dispatch({ type: "SET_PROFESSIONAL_GROUP", payload: res });
        return res;
      } catch (error) {
        console.error(error);
        return null;
      } finally {
        setProfessionalGroupSaving(false);
      }
    },
      [
        getProfessionalGroup,
        state.userData?.ud_id,
        state.userData?.professional_group_role_user_data?.professional_group_id,
      ]);

  ////////////////////////////// PROFESSIONAL GROUP //////////////////////////////
  ////////////////////////////// PROFESSIONAL GROUP //////////////////////////////

  // i would to get rid of this so hopefully we can get ride of the demo roles
  const [demoTranscaciontsAdded, setDemoTranscaciontsAdded] = useState(false);
  useEffect(() => {
    // catch when is demo clients and insert transactions.
    if (state.professionalGroup && !state.professionalGroup.onboarding_completed_at && state.clientRoles?.length > 0 && !demoTranscaciontsAdded) {
      //update the client roles with demo transactions
      const updatedRoles = state.clientRoles.map((role) => {
        if (role.is_demo_client) {
          const demoT = demoTransactions(state.userData?.full_name || null).find(
            (t) =>
              t.client_contact_email ===
              role.role_target_user_data?.contact_email
          );
          return {
            ...role,
            transactions: demoT ? [demoT] : []
          };
        }
        return role;
      });
      setDemoTranscaciontsAdded(true);
      dispatch({ type: "SET_CLIENT_ROLES", payload: updatedRoles });
    }
  }, [state.professionalGroup, demoTranscaciontsAdded, state.clientRoles, state.userData?.full_name]);

  // get owner's user datga. If it doesn't exist, create it.
  useEffect(() => {
    const abortController = new AbortController();

    const handleGetUserData = async (authUser: User, signal?: AbortSignal): Promise<void> => {
      try {
        const params = { ud_id: "", entity: "user_data" };
        const response = await genericGetUserData(params, signal);
        if (!signal?.aborted) {
          dispatch({ type: "SET_USERDATA", payload: response });
        }
      } catch (err) {
        if (abortController.signal.aborted) return;
        // if there's an error, create the user data object!
        try {
          const res = await handleAddOrEditOwnerUserData({
            full_name: authUser?.first_name,
            contact_email: authUser?.username,
            assigned_total_domain: "app.getsnug.com",
          });
          if (!res) {
            throw new Error("User data not created");
          }
          dispatch({ type: "SET_USERDATA", payload: res });
        } catch (error) {
          const searchParams = new URLSearchParams({
            returnTo: window.location.pathname,
          }).toString();
          const href = paths.auth.jwt.entryStart + `?${searchParams}`;
          router.replace(href);
        }
      } finally {
        if (!abortController.signal.aborted) {
          dispatch({ type: "SET_USERDATA_INITIAL_LOADING", payload: false });
        }
      }
    };

    if (user) {
      handleGetUserData(user, abortController.signal);
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, router]);

  // get client roles
  useEffect(() => {
    // abort signal
    const abortController = new AbortController();
    if (state.userData) {
      handleGetClientRoles(
        state.userData,
        searchAndPaginationState,
        abortController.signal
      );
    }

    return () => {
      abortController.abort();
    };
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.userData, searchAndPaginationState]
  );

  // get people roles data.
  useEffect(() => {
    const abortController = new AbortController();
    if (
      state.userData
    ) {
      handleGetClientCount(
        state.userData,
        abortController.signal
      );
    }

    return () => {
      abortController.abort();
    };
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      state.userData
    ]);

  // get professional group data
  useEffect(() => {
    // abort signal
    const abortController = new AbortController();
    if (state.userData?.professional_group_role_user_data) {
      handleGetProfessionalGroup(
        state.userData?.professional_group_role_user_data.professional_group_id,
        abortController.signal
      );
    }

    return () => {
      abortController.abort();
    };
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      state.userData?.professional_group_role_user_data,

    ]);

  useEffect(() => {
    if (state.professionalGroup) {
      gtm.push({
        event: "professionalGroupDataChange",
        professionalGroup: state.professionalGroup,
      });
      // if the user just logged in, push a login event - this is registered in the auth context. This is marketing engineering so WAHHHHHH
      if (sessionStorage.getItem("login")) {
        gtm.push({
          event: "login",
          professionalGroup: state.professionalGroup,
        });
        sessionStorage.removeItem("login");
      }
    }
  }, [state.professionalGroup]);

  useEffect(() => {
    if (
      state.userData &&
      !state.userData?.professional_group_role_user_data
    ) {
      const href = paths.auth.jwt.entryStart + `?ep=register&lb=true`;
      router.replace(href);
    }
  }, [state.professionalGroup, state.userData, router]);

  if (state.professionalGroup?.dashboard_wl_on &&
    dashboard_domain !== state.professionalGroup.white_label_config?.dashboard_domain
  ) {
    signOut();
    const href = paths.auth.jwt.entryStart + `?ep=login&lb=true`;
    router.replace(href);
    return <SplashScreen />;
  }

  if (!state.userData || professionalGroupLoading) {
    return <SplashScreen />;
  }

  const isProTier =
    state.professionalGroup?.professional_payment_tier === "PRO";
  const isWhiteLabel =
    state.professionalGroup?.professional_payment_tier === "WHITE_LABEL";
  const isCobranding =
    state.professionalGroup?.professional_payment_tier === "COBRANDING";
  const isFirmAdmin =
    state.userData.professional_group_role_user_data?.role ===
    "PRO_GROUP_ADMIN";

  return (
    <UserDataContext.Provider
      value={{
        userData: state.userData,
        targetUserDatas: state.targetUserDatas,
        userDataInitialLoading: state.userDataInitialLoading,
        userDataSaving: state.userDataSaving,
        clientRoles: state.clientRoles,
        clientRolesSearchCount: state.clientRolesSearchCount,
        clientRolesTotalCount: state.clientRolesTotalCount,
        clientRolesTotalCountLoading: state.clientRolesTotalCountLoading,
        clientRolesLoading: state.clientRolesLoading,
        clientRolesSavingNew: state.clientRolesSavingNew,
        clientRolesFilestoUpload: state.clientRolesFilestoUpload,
        professionalGroup: state.professionalGroup,
        professionalGroupSaving: professionalGroupSaving,
        searchAndPaginationState: searchAndPaginationState,
        isProTier,
        isWhiteLabel,
        isCobranding,
        isFirmAdmin,
        handleAddClient,
        handleUpdateProfessionalGroup,
        handleUpdateProfessionalGroupImage,
        handleDeleteProGroupImage,
        handleArchiveClientRole,
        handleDownloadClientEstateDocument,
        handleclientRolesFileRemove,
        handleclientRolesFilesDrop,
        handleclientRolesFilesRemoveAll,
        handleUploadClientRolesFiles,
        handleAddOrEditTargetUserData,
        handleDeleteProfessionalImage,
        handleUpdateProfessionalImage,
        handleAddOrEditOwnerUserData,
        handleInvitePro,
        handleFiltersChange,
        handleSortChange,
        handlePageChange,
        handleRowsPerPageChange,
        handleInviteClient,
        handleSecondDegreeInvite,
        handleUpgradeToAdmin,
        handleDowngradeToAgent,
        handleDeleteProGroupRole,
        handleAdminUpdateClient,
        handleTransferClient,
        handleRequestAccessFromLead,
        handleEditClientEmail,
        handleCreateWhiteLabelConfigOnProGroup,
        handleRefreshProGroup,
        handlePatchSelfProfessionalGroupRole,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export default function useUserDataProvider() {
  return useContext(UserDataContext);
}
