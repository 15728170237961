import type { FC, ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { SplashScreen } from "src/components/splash-screen";

interface AuthGuardProps {
  children: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const router = useRouter();
  const { isAuthenticated, user, isEmailVerified } = useAuth();
  const [checked, setChecked] = useState<boolean>(false);

  const check = useCallback(async (): Promise<void> => {
    try {
      if (!isAuthenticated) {
        const searchParams = new URLSearchParams({
          returnTo: window.location.pathname,
        }).toString();
        const href = paths.auth.jwt.entryStart + `?${searchParams}`;
        router.replace(href);
      } else if (!isEmailVerified) {
        const searchParams = new URLSearchParams({
          ep: "verify",
          ee: user?.username || "",
          pb: "true",
        }).toString();
        const href = paths.auth.jwt.entryStart + `?${searchParams}`;
        router.replace(href);
      } else {
        setChecked(true);
      }
    } catch (err) {
      // ... error handling
    }
  }, [isAuthenticated, router, user, isEmailVerified]);

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    // return null = was this previously
    return <SplashScreen />;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
